<template>
  <div
    v-if="time === undefined"
    class="ft-bold text--primary xl:text-lg lg:text-lg md:text-lg sm:text-base"
  >
    Loading...
  </div>
  <div v-else class="d-flex align-center justify-center">
    <div v-if="!duration" class="mr-4">Time Remaining:</div>
    <div
      v-for="(element, index) in timeElements"
      :key="index"
      class="d-flex align-center"
    >
      <div
        class="rounded py-1 px-2 xl:text-2xl ft-bold d-flex align-center justify-center"
        :class="classes.clock"
      >
        {{ element }}
      </div>
      <div v-if="index < timeElements.length - 1" class="px-1">:</div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["exam", "currentSection"],
  props: ["duration"],
  data() {
    return {
      time: undefined,
      id: undefined,
      timeLowWarning: false
    };
  },
  computed: {
    startTime() {
      if (this.duration) {
        return Date.now() / 1000;
      } else {
        if (this.currentSection) {
          if (this.currentSection.order > 0)
            return ((Date.now() / 1000) + (this.currentSection.duration));
          else return Date.parse(this.exam?.student_exam?.start_at) / 1000;
        } else {
          return Date.parse(this.exam?.student_exam?.start_at) / 1000;
        }
      }
    },
    examDuration() {
      if (this.duration) {
        return this.duration;
      } else if (!this.duration && this.currentSection) {
        return this.currentSection?.duration * 60;
      } else return this.exam?.student_exam?.total_duration * 60;
    },
    timeDelta() {
      return Math.floor(
        (this.startTime || 0) -
          (this.time?.current_time || 0) +
          (this.examDuration || 0)
      );
    },
    timeElements() {
      return [this.hoursLeft, this.minutesLeft, this.secondsLeft];
    },
    hoursLeft() {
      let delta = Math.max(0, this.timeDelta);
      return Math.floor(delta / 3600)
        .toString()
        .padStart(2, "0");
    },
    minutesLeft() {
      let delta = Math.max(0, this.timeDelta);
      return Math.floor((delta % 3600) / 60)
        .toString()
        .padStart(2, "0");
    },
    secondsLeft() {
      let delta = Math.max(0, this.timeDelta);
      return Math.ceil(delta % 60)
        .toString()
        .padStart(2, "0");
    },
    classes() {
      // less than 10 min warning
      return {
        clock: this.timeDelta < 300 && !this.duration ? "red lighten-5 decline--text blink" : "white"
      };
    }
  },
  watch: {
    timeDelta: {
      immediate: true,
      handler(value) {
        if (value <= 0 && this.time !== undefined) {
          this.$emit("time-over");
        }
      }
    }
  },
  async created() {
    await this.getTime();
    this.setTimer();
  },
  methods: {
    async getTime() {
      this.time = await this.$store.dispatch("studentExam/getCurrentTime");
    },
    setTimer() {
      if (this.time && !this.id) {
        this.id = setInterval(() => {
          this.time["current_time"] += 1;
        }, 1000);
      }
    },
    endTimer() {
      clearInterval(this.id);
    }
  }
};
</script>

<style scoped lang="scss">
.blink {
  animation: blinker 0.5s linear infinite;
}
@keyframes blinker {
  100% {
    opacity: 60%;
  }
}
</style>
