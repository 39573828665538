var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.time === undefined
    ? _c(
        "div",
        {
          staticClass:
            "ft-bold text--primary xl:text-lg lg:text-lg md:text-lg sm:text-base",
        },
        [_vm._v(" Loading... ")]
      )
    : _c(
        "div",
        { staticClass: "d-flex align-center justify-center" },
        [
          !_vm.duration
            ? _c("div", { staticClass: "mr-4" }, [_vm._v("Time Remaining:")])
            : _vm._e(),
          _vm._l(_vm.timeElements, function (element, index) {
            return _c(
              "div",
              { key: index, staticClass: "d-flex align-center" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "rounded py-1 px-2 xl:text-2xl ft-bold d-flex align-center justify-center",
                    class: _vm.classes.clock,
                  },
                  [_vm._v(" " + _vm._s(element) + " ")]
                ),
                index < _vm.timeElements.length - 1
                  ? _c("div", { staticClass: "px-1" }, [_vm._v(":")])
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }